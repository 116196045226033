<template>
  <div id="app">
    <div class="container">
      <product-page
        v-if="isShowPage === 'product'"
        :setIsChangePage="setIsChangePage"
        @setIsChangePage="setIsChangePage"
        @setIsOpenProductPage="setIsOpenProductPage"
      />
      <product-info-page
        v-if="isShowPage === 'productInfo'"
        :openedProduct="openedProduct"
        @setIsChangePage="setIsChangePage"
      />
      <card-page
        v-if="isShowPage === 'cart'"
        @setIsChangePage="setIsChangePage"
      />
      <make-order-page
        v-if="isShowPage === 'makeOrder'"
        @setIsChangePage="setIsChangePage"
      />
      <is-success-page
        v-if="isShowPage === 'success'"
        @setIsChangePage="setIsChangePage"
      />
    </div>
  </div>
</template>

<script>
import ProductPage from "./resources/js/pages/ProductPage.vue";
import ProductInfoPage from "./resources/js/pages/ProductInfoPage.vue";
import CardPage from "./resources/js/pages/CardPage.vue";
import MakeOrderPage from "./resources/js/pages/MakeOrderPage.vue";
import IsSuccessPage from "./resources/js/pages/IsSuccessPage.vue";
import { mapMutations } from "vuex";

export default {
  name: "app",
  components: {
    ProductPage,
    ProductInfoPage,
    CardPage,
    MakeOrderPage,
    IsSuccessPage,
  },

  data() {
    return {
      isShowPage: "product",
      openedProduct: null,
      categories: [],
      openedCategoryProducts: [],
      usedCart: {},
      mainBunner: [],
      Telegram: window.Telegram.WebApp,
      //  keyboardHeight: 0
    };
  },

  async mounted() {
    window.scrollTo(0, 0);
    try {
      this.Telegram.expand();
      this.Telegram.requestFullscreen();
      const data = JSON.stringify({ allow_vertical_swipe: false });
      window.TelegramWebviewProxy?.postEvent(
        "web_app_setup_swipe_behavior",
        data
      );
    } catch (error) {
      console.log(error.message);
    }
    this.$store.dispatch(
      "getAllProducts",
      this.trimNonNumericStart(window.location.pathname)
    );
    this.$store.dispatch(
      "getCustomerInfo",
      this.trimNonNumericStart(window.location.pathname)
    );
    this.$store.dispatch(
      "getCartProducts",
      this.trimNonNumericStart(window.location.pathname)
    );
  },
  methods: {
    ...mapMutations(["addPerPage", "setChangeSearchText"]),
    setIsChangePage(page) {
      this.isShowPage = page;
    },
    trimNonNumericStart(inputString) {
      let match = inputString.match(/^\D/);

      if (match) {
        let nonNumericChar = match[0];
        let index = inputString.indexOf(nonNumericChar);
        return inputString.slice(index + 1);
      }
      alert(inputString);
      return inputString;
    },
    setIsOpenProductPage() {
      this.setIsChangePage("productInfo");
    },
    setIsOpenProductFromBunner(value) {
      // this.isShowPage = 'productInfo'
      this.openedProduct = value;
    },
  },
  computed: {
    cartElements() {
      return this.$store.getters.getCustomerCart;
    },
  },
  watch: {
    async isShowPage(newValue, oldValue) {
      if (newValue === "product") {
        this.$store.dispatch(
          "getAllProducts",
          this.trimNonNumericStart(window.location.pathname)
        );
        this.$store.dispatch(
          "getCustomerInfo",
          this.trimNonNumericStart(window.location.pathname)
        );
        this.$store.dispatch(
          "getCartProducts",
          this.trimNonNumericStart(window.location.pathname)
        );
      }
      let value = oldValue;
      value;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 10px;
}
.container {
  background: #f3f3f5;
  height: 100vh;
  padding-top: 100px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  grid-auto-rows: auto;
  padding: 0px 15px 100px;
}

.cards.product-list {
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 0px;
  padding: 0px 0px 100px;
}

.appbar {
  position: fixed;
  z-index: 99;
  top: 0px;
  width: 100%;
  height: 48px;
  background: transparent;
  padding: 5px;
  display: grid;
  grid-template-columns: 36px auto 36px;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 0px 0px;
  background-color: rgba(255, 255, 255, 0.79);
}

.to_order_appbar {
  position: fixed;
  z-index: 99;
  top: 0px;
  width: 100%;
  height: 48px;
  background: transparent;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 960px;
  backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 0px 0px;
  background-color: rgba(255, 255, 255, 0.79);
}

.mirrorX {
  transform: scale(-1, 1);
}

.appbar_btn {
  position: relative;
  height: 36px;
  width: 36px;
}

.iconbtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding: 1.7px 0 0 1.7px;
  background-color: unset;
  border: unset;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-right: 20px;
}

.appbar_category {
  cursor: pointer;
  text-align: center;
  -webkit-line-clamp: 2;
}

.shopcart_iconTotal {
  position: absolute;
  font-size: 8px;
  font-weight: bold;
  min-width: 2.5em;
  padding: 0 0.2em;
  top: 0;
  right: -0.2em;
  border: 1px solid #f56e22;
  border-radius: 8px;
  background: #ffede2;
}

.list {
  padding-top: 58px;
}

.shopcart_prompt {
  display: flex;
  margin: auto;
  margin-top: 2em;
  width: 80%;
  position: relative;
  align-items: center;
  min-width: 290px;
  max-width: 600px;
  min-height: 73px;
  background-color: #000000;
  border-radius: 8px;
  cursor: pointer;
}

.shopcart_prompt_text {
  color: #f3f3f3;
  margin: auto;
  padding: 20px;
  text-align: center;
  font-size: 14px;
}

.shopcart_prompt_text_info {
  font-family: sans-serif;
}

.shopcart_prompt_icon {
  position: absolute;
  top: -15px;
  right: 20px;
}

.shopcart {
  padding: 1em 5px 7em;
  padding-top: 58px;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopcart_product {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #eeeeee;
  padding: 0.6em;
}

.shopcart_product_footer,
.shopcart_product_header {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.shopcart_product_footer--price,
.shopcart_product_header--price {
  color: #2196f3;
  min-width: 7em;
  text-align: end;
}

.shopcart_delivery {
  padding: 0.6em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #eeeeee;
}

.shopcart_total {
  padding: 0.6em;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

button,
button:active,
button:focus {
  outline: none;
}

.cart {
  right: 20px;
}

/**new styles*/

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.form-control {
  padding: 8px 15px;
  border: 1px solid #eee;
  line-height: 22px;
  border-radius: 5px;
  font-size: 13px;
  color: #999;
}

input:focus,
input:active,
.form-control:focus,
.form-control:active {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #eee;
  color: #777;
}

.search-text {
  background: #f0f0f0 !important;
  padding: 12px;
  border: none;
  color: #000;
  font-family: "Space Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  padding-left: 50px;
}

.search-text::placeholder {
  color: #707070;
  font-family: "Space Grotesk", sans-serif;
}

.search-iconn:focus-visible,
.search-text:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.search-filter-btn a {
  border-radius: 8px;
  background: #ff4c3b;
  padding: 12px;
  display: inline-block;
}

.search-input-icon {
  position: absolute;
  top: 12px;
  left: 12px;
}

.filter-button-icon {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  color: #fff;
}

.filter-button {
  width: 48px;
  height: 48px;
  background: #01bace;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.nav-pills {
  display: flex;
  list-style: none;
}

.bottombar {
  position: fixed;
  bottom: 0;
  left: 50%;
  padding: 12px 0px;
  z-index: 10;
  transform: translateX(-50%);
  width: 100%;
  max-width: 960px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 0px 0px;
  background-color: rgba(255, 255, 255, 0.79);
  backdrop-filter: blur(10px);
}

.tabs-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: auto;
}

.tabs-inner button {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #999999;
  margin: 0px 40px;
  position: relative;
}

.tabs-inner .tab.active {
  color: #2196f3;
}

.tabs-inner .tab.active svg {
  fill: #2196f3;
}

.tabs-inner button svg {
  width: 30px;
  height: auto;
  margin-bottom: 8px;
  fill: #999999;
}

.tabs-inner button .total-sum {
  position: absolute;
  font-size: 10px;
  width: 100%;
  top: 0;
  right: -32px;
  background: #2196f3;
  color: #fff;
  border-radius: 6px;
  padding: 2px 4px;
}

.to_order_menu_page {
  padding-top: 58px;
}

.to_order_title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
}

.to_order_menu_container {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* ........................... */

.text-field {
  margin-bottom: 1rem;
}

.text-field__label {
  display: block;
  margin-bottom: 0.25rem;
  color: #757575;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.text-field__input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.text-field__input[type="search"]::-webkit-search-decoration,
.text-field__input[type="search"]::-webkit-search-cancel-button,
.text-field__input[type="search"]::-webkit-search-results-button,
.text-field__input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.text-field__input::placeholder {
  color: #212529;
  opacity: 0.4;
}

.text-field__input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #bdbdbd;
  outline: 0;
}

.text-field__input:disabled,
.text-field__input[readonly] {
  background-color: #f5f5f5;
  opacity: 1;
}

/* with floating label */
.text-field_floating {
  position: relative;
}

.text-field_floating .text-field__input {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
  padding: 1rem 0.75rem;
}

.text-field_floating .text-field__label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.text-field_floating .text-field__input::-moz-placeholder {
  color: transparent;
}

.text-field_floating .text-field__input::placeholder {
  color: transparent;
}

.text-field_floating .text-field__input:focus,
.text-field_floating .text-field__input:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.text-field_floating .text-field__input:focus ~ .text-field__label,
.text-field_floating
  .text-field__input:not(:placeholder-shown)
  ~ .text-field__label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.75rem) translateX(0.15rem);
}

.wrong_label {
  color: #ff2c20;
}

.wrong_format_message {
  margin-top: 8px;
  color: #ff2c20;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.opened_input_options_container {
  width: 100%;
  position: relative;
}

.opened_input_result_text {
  position: relative;
  border-radius: 12px;
  border: 1px solid #bdbdbd;
  padding: 8px 16px;
  height: 58px;
}

.opened_input_results {
  position: absolute;
  width: 100%;
  border: 1px solid #bdbdbd;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  z-index: 100;
}

.opened_input_text {
  padding: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.opened_input_icon {
  position: absolute;
  right: 20px;
  top: 18px;
  transition: all 0.5s ease;
}

.opened_input_rotate {
  transform: rotate(180deg);
}

.opened_input_placeholder {
  position: absolute;
  top: 18px;
  left: 10px;
  height: 100%;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  color: #757575;
  font-size: 16px;
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.active_opened {
  top: 6px;
  left: 10px;
  font-size: 13px;
}

.used_input_delivery_name {
  margin-top: 20px;
}

.small_inputs_container {
  display: flex;
  gap: 20px;
}

.error_number_icon {
  position: absolute;
  top: 18px;
  right: 20px;
  color: #ff2c20;
}

.success-payment-method-icon {
  position: absolute;
  top: 18px;
  right: 20px;
}

.send_order_button {
  padding: 16px 24px;
  width: 100%;
  border-radius: 12px;
  background: #1f1f1f;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 700px) {
  .to_order_menu_container {
    max-width: 50%;
  }
}

/* new styles */

.top-navbar_full {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  padding-top: 100px;
}
.skip_btn a {
  color: #707070;
  text-align: right;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.noresult-screen-full {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.to-cart-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.cart-counter {
  position: absolute;
  height: 14px;
  min-width: 14px;
  background: #c8392b;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 8px;
  font-weight: 700;
  line-height: 8px;
  top: -1px;
  right: -4px;
}
.logo-button {
  background: transparent;
  border: none;
  cursor: pointer;
}
</style>
