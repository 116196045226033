const baseApiUrl = process.env.VUE_APP_MAIN_DOMAIN_URL;

export const GET_CUSTOMER_INFORMATION = async (id) => {
  const response = await fetch(
    `${baseApiUrl}/api/v1/shop/customer?customer_id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
      },
    }
  );
  const result = await response.json();
  return result;
};

export const GET_ALL_PRODUCTS = async (id) => {
  const response = await fetch(
    `${baseApiUrl}/api/v1/shop/products/get?customer_id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
      },
    }
  );
  const result = await response.json();
  return result;
};

export const GET_ONE_PRODUCT = async (product) => {
  const response = await fetch(
    `${baseApiUrl}/api/v1/shop/products/${product}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
      },
    }
  );
  const result = await response.json();
  return result;
};

export const GET_CITYS = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/order/city`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};

export const GET_ORDER_BRANCH = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/order/branch`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};

export const SET_ORDER_BRANCH_SAVE = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/order/save`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};

export const GET_COME_TO_PAY = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/order/pay`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};

export const GET_ORDER_CART = async (id) => {
  const response = await fetch(
    `${baseApiUrl}/api/v1/shop/cart/get?customer_id=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
      },
    }
  );
  const result = await response.json();
  return result;
};

export const GET_ADD_TO_CART = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/cart/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};

export const GET_ADD_COUNTER_CART = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/cart/plus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};

export const GET_MINUS_COUNTER_CART = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/cart/minus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};

export const GET_DELETE_CART = async (data) => {
  const response = await fetch(`${baseApiUrl}/api/v1/shop/cart/delete`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
    },
    body: data,
  });
  const result = await response.json();
  return result;
};
