import {
  GET_ONE_PRODUCT,
  GET_ALL_PRODUCTS,
  GET_ADD_TO_CART,
  GET_ORDER_CART,
  GET_ADD_COUNTER_CART,
  GET_MINUS_COUNTER_CART,
  GET_DELETE_CART,
  SET_ORDER_BRANCH_SAVE,
  GET_COME_TO_PAY,
  GET_CUSTOMER_INFORMATION,
} from "../../resources/js/helpers/requests";

export default {
  state: {
    perPage: "",
    customerInformation: {},
    openProduct: {},
    products: [],
    max_price: 0,
    customerCart: {},
    order_id: null,
    searchText: "",
    filterParametrs: {},
    checkedCategory: null,
    succedOrder: null,
  },
  mutations: {
    addPerPage(state, page) {
      state.perPage = page;
    },
    setChangeCustomerInformation(state, customer) {
      state.customerInformation = customer;
    },
    setIsOpenProductInfo(state, product) {
      state.openProduct = product;
    },
    setAddProducts(state, products) {
      state.products = products;
    },
    setAddMaxPrice(state, price) {
      state.max_price = price;
    },
    setAddProductToCart(state, product) {
      state.customerCart = product;
    },
    setOrderId(state, id) {
      state.order_id = id;
    },
    setChangeSearchText(state, text) {
      state.searchText = text;
    },
    setFilterParametrs(state, data) {
      state.filterParametrs = data;
    },
    deleteFilterCategoryParametr(state) {
      state.filterParametrs = delete state.filterParametrs.category_ids;
    },
    setSaveSuccedOrder(state, data) {
      state.succedOrder = data;
    },
    setFilterPriceParametrs(state, data) {
      state.filterParametrs = {
        ...state.filterParametrs,
        price_from: data.price_from,
        price_to: data.price_to,
      };
    },
    deleteFilterParametr(state) {
      state.filterParametrs = {};
    },
    setCheckedCategory(state, data) {
      state.checkedCategory = data;
    },
  },
  actions: {
    async getCustomerInfo({ commit }, id) {
      let result = await GET_CUSTOMER_INFORMATION(id);
      commit("setChangeCustomerInformation", result.data);
    },
    async getOneProduct({ commit }, id) {
      let result = await GET_ONE_PRODUCT(id);
      commit("setIsOpenProductInfo", result.data);
      return result;
    },
    async getAllProducts({ commit }, id) {
      let result = await GET_ALL_PRODUCTS(id);
      commit("setAddProducts", result?.data);
    },
    async addProductToCart({ commit }, body) {
      let result = await GET_ADD_TO_CART(body);
      commit("setAddProductToCart", result.data);
      return result;
    },
    async getCartProducts({ commit }, id) {
      let result = await GET_ORDER_CART(id);
      commit("setAddProductToCart", result.data);
    },
    async getOneMoreProduct({ commit }, data) {
      let result = await GET_ADD_COUNTER_CART(data);
      commit("setAddProductToCart", result.data);
    },
    async getMinusProduct({ commit }, data) {
      let result = await GET_MINUS_COUNTER_CART(data);
      commit("setAddProductToCart", result.data);
    },
    async deleteProductFromCart({ commit }, data) {
      let result = await GET_DELETE_CART(data);
      commit("setAddProductToCart", result.data);
    },

    async setSaveOrder({ commit }, data) {
      let resultBranch = await SET_ORDER_BRANCH_SAVE(data);
      commit("setSaveSuccedOrder", resultBranch.data);
      let closeData = {
        order_id: resultBranch.data.id,
      };
      let result = await GET_COME_TO_PAY(JSON.stringify(closeData));
      return result;
    },
  },
  getters: {
    perPageName: (state) => {
      return state.perPage;
    },
    customerInfo: (state) => {
      return state.customerInformation;
    },
    openedProduct: (state) => {
      return state.openProduct;
    },
    getProducts: (state) => {
      return state.products;
    },
    getMaxPrice: (state) => {
      return state.max_price;
    },
    getCustomerCart: (state) => {
      return state.customerCart;
    },
    getOrderId: (state) => {
      return state.order_id;
    },
    getSearchText: (state) => {
      return state.searchText;
    },
    getFilterParametrs: (state) => {
      return state.filterParametrs;
    },
    getCheckedCategory: (state) => {
      return state.checkedCategory;
    },
    getSuccedOrder: (state) => {
      return state.succedOrder;
    },
  },
};
